::-webkit-calendar-picker-indicator {
  filter: invert(49%) sepia(8%) saturate(5323%) hue-rotate(164deg)
    brightness(105%) contrast(83%);
}
.reverse-row {
  flex-direction: row-reverse;
}
.box-add-module {
  background-color: #f5f5f5;
  border: 1px dashed #ced4da;
  border-radius: 6px;
  height: 86px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
