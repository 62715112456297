.disabledButon {
  display: flex;
  background-color: #e2ecfb;
  justify-content: center !important;
  border-radius: 10px;
  color: #f1f1f1;
  padding: 0.5rem 1.2rem;
  border: 0px;
  outline: 0px !important;
  font-family: "Open Sans", sans-serif !important;
  width: 120px;
}

@media only screen and (max-width: 1199px) {
  .queryGrid {
    display: none;
  }
}

.open {
  color: #007e8c;
  font-weight: 600;
}

.loss {
  color: #FF0000;
  font-weight: 600;
}

.null {
  color: "#000";
}