.social-media {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.MuiTypography-h4 {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 2px;
}

.MuiIconButton-root {
  padding: 0;
  padding-right: 5px;
}

.text-item {
  font-size: 18px;
  color: #ebebeb;
}

.footer-social-media input {
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 10px;
}