.boxAddModule {
    background-color: #f5f5f5;
    border: 1px dashed #CED4DA;
    box-sizing: border-box;
    border-radius: 6px;
    height: 86px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputDisabled{
    & .Mui-disabled {
        background: #F5F5F5;
      border: 1px solid #CED4DA;
      border-radius: 6px;
    }
}

.seeIcons:hover {
    text-decoration: underline;
}
.icon-picker:hover{
    cursor: pointer !important;
}
.add-module-form{
    max-height: 60vh;
    overflow-y: auto;
}