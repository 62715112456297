.labelField {
  color: #495057 !important;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 12.1px;
}

.labelP {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}

@media only screen and (max-width: 1199px) {
  .queryGrid {
    display: none;
  }
}

.disabledButon {
  display: flex;
  background-color: #e2ecfb;
  justify-content: center !important;
  border-radius: 10px;
  color: #f1f1f1;
  padding: 0.5rem 1.2rem;
  border: 0px;
  outline: 0px !important;
  font-family: "Open Sans", sans-serif !important;
  width: 120px;
}

.finantial-styles {
  margin: 8px 24px;
  padding: 20px 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  border-radius: 8px;
  opacity: 1;
}
