@media only screen and (max-width: 1280px) {
  .notifications {
    display: none;
  }
  .contentBox {
    width: 100%;
    padding: 12px;
  }
}

@media only screen and (min-width: 1281px) {
  .notifications {
    width: 25%;
    padding: 0 !important;
    margin: 0 !important;
  }
  .contentBox {
    width: 75%;
    padding: 12px;

  }
}
