.operator-content-form {
  .MuiGrid-container.MuiGrid-item {
    gap: 15px;
  }
  .content-flex {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
    align-items: center;
    span {
      grid-column: span 8;
    }
    .MuiPaper-elevation {
      grid-column: span 4;
    }
  }
  h3 {
    color: #333;
  }
}
