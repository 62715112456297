.card-ranking-container {
  background: rgba(213, 213, 213, 0.2) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px !important;
  //height: 235px;
}
.card-ranking-title {
  color: #333 !important;
  font-weight: 700 !important;
  //font-size: 16px;
  line-height: 19px !important;
}
.identifier-ranking {
  background: #747474;
  border-radius: 2px;
  width: 20px;
  height: 15px;
  text-align: center;
}
/* .identifier-ranking p {
  font-size: 0.7rem;
  color: "#fff";
} */
.text-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  color: #747474;
}
.subtitle-list {
  font-weight: 700;
font-size: 12px;
line-height: 14px;
/* identical to box height */


color: #747474;
}
