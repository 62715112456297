.tabs {
  .tab {
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom-color: #02d0e7;
    }

    &.active {
      color: #02d0e7;
      border-bottom-color: #02d0e7;
    }

    .tab-label {
      font-weight: bold;
    }

    .tab-content {
      display: none;
    }

    &.active .tab-content {
      display: block;
    }
  }
  .tab-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
  }
}
