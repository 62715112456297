.multi-select {
  position: relative;
  width: 200px; // Ajusta según tus necesidades
}

.selected-items {
  padding: 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 10;
  max-height: 200px; // Ajusta según tus necesidades
  overflow-y: auto;
}

.search-box {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.search-box input {
  border: none;
  outline: none;
  flex: 1;
  padding: 8px;
}

.search-icon {
  margin-left: 8px;
}

.dropdown__item {
  padding: 8px;
  cursor: pointer;
}

.dropdown__item:hover {
  background-color: #f0f0f0;
}

.dropdown__item input {
  margin-right: 8px;
}
