.promotions-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  .actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
