.multi-select {
  position: relative;
  width: 300px;

  .selected-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 10px;
    border: 1px solid #ccc;
    min-height: 40px;
    cursor: pointer;
    position: relative;

    &::after {
      content: "▼";
      position: absolute;
      right: 10px;
      font-size: 12px;
      pointer-events: none;
    }

    &__item {
      background: #e0e0e0;
      border-radius: 3px;
      padding: 5px;
      margin: 2px;
      display: flex;
      align-items: center;

      .remove {
        margin-left: 5px;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;

    &__item {
      display: flex;
      align-items: center;
      padding: 5px 10px;

      &:hover {
        background: #f0f0f0;
      }

      input {
        margin-right: 10px;
      }
    }
  }

  .search-box {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;

    input {
      border: none;
      outline: none;
      flex-grow: 1;
      padding-right: 25px;
    }

    .search-icon {
      position: absolute;
      right: 10px;
      color: #ccc;
      font-size: 16px;
      pointer-events: none;
    }
  }
}
