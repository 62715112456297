.circle-btn-green {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007e8c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-btn-green:hover {
  background-color: #00e5ff;
  border-color: #64f6ff;
}
.option-btn {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dadada;
  border-radius: 5px;
}
.option-btn-active {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007e8c;
  border-radius: 5px;
}
.option-btn:hover {
  cursor: pointer;
  background-color: darken($color: #dadada, $amount: 4);
  p {
    color: darken($color: #333333, $amount: 4) !important;
  }
}
.option-btn-active:hover {
  cursor: pointer;
  background-color: darken($color: #007e8c, $amount: 4);
  p {
    color: darken($color: #fff, $amount: 4) !important;
  }
}

@media only screen and (max-width: 1525px) {
  .txt-primary-sm {
    font-size: 10px !important ;
  }
}
@media only screen and (max-width: 1000px) {
  .txt-primary-sm {
    font-size: 9px !important ;
  }
}
