.picker-container {
  background: #f5f5f5;
  border: 1px solid #ced4da;
  border-radius: 6px;
  width: 375px;
  max-height: 223px;
  overflow-y: auto;
  overflow-x: hidden;
    position: absolute;
    z-index: 4;

 
}
.picker-container .item {
  background-color: #fff;
  //width: 52px;
  width: "100%";
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
}
.picker-container .item span {
    
    font-size: 8px ;
  color: #555555;

  }
.picker-icon {
    font-size: 25px;
  color: #555555 !important;
    
}
