.titleUser {
  font-weight: 550;
  font-size: 17px;
  color: black !important;
}

.iconUser {
  color: #0097a8;
}

.titleInfo {
  font-size: 15px;
  color: black !important;
}

/**
    BARRA SUPERIOR
*/

.barraUpLeft {
  width: 2%;
}

.barraUpInfo {
  width: 16%;
}

.barraUpRigth {
  width: 82%;
}

/**
    BARRA INFERIOR
*/
.barraDownLeft {
  width: 2%;
}

.barraDownInfo {
  width: 18%;
}

.barraDownRigth {
  width: 80%;
}

@media only screen and (max-width: 1670px) {
  .barraDownInfo {
    width: 20%;
  }

  .barraDownRigth {
    width: 78%;
  }
}

@media only screen and (max-width: 1620px) {
  .barraUpInfo {
    width: 18%;
  }

  .barraUpRigth {
    width: 80%;
  }
}

@media only screen and (max-width: 1550px) {
  .barraDownInfo {
    width: 22%;
  }

  .barraDownRigth {
    width: 76%;
  }
}

@media only screen and (max-width: 1470px) {
  .barraUpInfo {
    width: 20%;
  }

  .barraUpRigth {
    width: 78%;
  }
  .barraDownInfo {
    width: 23%;
  }

  .barraDownRigth {
    width: 75%;
  }
}

@media only screen and (max-width: 1430px) {
  .barraUpInfo {
    width: 22%;
  }

  .barraUpRigth {
    width: 76%;
  }
  .barraDownInfo {
    width: 25%;
  }

  .barraDownRigth {
    width: 73%;
  }
}

@media only screen and (max-width: 1280px) {
  .barraUpInfo {
    width: 16%;
  }

  .barraUpRigth {
    width: 82%;
  }
  .barraDownInfo {
    width: 19%;
  }

  .barraDownRigth {
    width: 79%;
  }
}

@media only screen and (max-width: 1240px) {
  .barraUpInfo {
    width: 18%;
  }

  .barraUpRigth {
    width: 80%;
  }
  .barraDownInfo {
    width: 21%;
  }

  .barraDownRigth {
    width: 77%;
  }
}

@media only screen and (max-width: 1140px) {
  .barraUpInfo {
    width: 20%;
  }

  .barraUpRigth {
    width: 78%;
  }
  .barraDownInfo {
    width: 24%;
  }

  .barraDownRigth {
    width: 74%;
  }
}

@media only screen and (max-width: 1050px) {
  .barraUpInfo {
    width: 23%;
  }

  .barraUpRigth {
    width: 75%;
  }
  .barraDownInfo {
    width: 27%;
  }

  .barraDownRigth {
    width: 71%;
  }
}

@media only screen and (max-width: 950px) {
  .barraUpInfo {
    width: 26%;
  }

  .barraUpRigth {
    width: 72%;
  }
  .barraDownInfo {
    width: 30%;
  }

  .barraDownRigth {
    width: 68%;
  }
}

@media only screen and (max-width: 860px) {
  .barraUpInfo {
    width: 30%;
  }

  .barraUpRigth {
    width: 68%;
  }
  .barraDownInfo {
    width: 34%;
  }

  .barraDownRigth {
    width: 64%;
  }
}

@media only screen and (max-width: 790px) {
  .barraUpInfo {
    width: 34%;
  }

  .barraUpRigth {
    width: 64%;
  }
  .barraDownInfo {
    width: 38%;
  }

  .barraDownRigth {
    width: 60%;
  }
}

@media only screen and (max-width: 710px) {
  .barraUpInfo {
    width: 38%;
  }

  .barraUpRigth {
    width: 60%;
  }
  .barraDownInfo {
    width: 43%;
  }

  .barraDownRigth {
    width: 55%;
  }
}
@media only screen and (max-width: 660px) {
  .barraUpInfo {
    width: 42%;
  }

  .barraUpRigth {
    width: 56%;
  }
  .barraDownInfo {
    width: 49%;
  }

  .barraDownRigth {
    width: 49%;
  }
}

@media only screen and (max-width: 600px) {
  .barraUpInfo {
    width: 46%;
  }

  .barraUpRigth {
    width: 52%;
  }
  .barraDownInfo {
    width: 55%;
  }

  .barraDownRigth {
    width: 43%;
  }
}

@media only screen and (max-width: 555px) {
  .barraUpInfo {
    width: 52%;
  }

  .barraUpRigth {
    width: 46%;
  }
  .barraDownInfo {
    width: 62%;
  }

  .barraDownRigth {
    width: 36%;
  }
}

@media only screen and (max-width: 512px) {
  .barraUpInfo {
    width: 58%;
  }

  .barraUpRigth {
    width: 40%;
  }
  .barraDownInfo {
    width: 69%;
  }

  .barraDownRigth {
    width: 29%;
  }
}

@media only screen and (max-width: 512px) {
  .barraUpInfo {
    width: 70%;
  }

  .barraUpRigth {
    width: 28%;
  }
  .barraDownInfo {
    width: 82%;
  }

  .barraDownRigth {
    width: 16%;
  }
}

@media only screen and (max-width: 430px) {
  .barraUpInfo {
    width: 88%;
  }

  .barraUpRigth {
    width: 10%;
  }
  .barraDownInfo {
    width: 90%;
  }

  .barraDownRigth {
    width: 8%;
  }
}

.gridButton {
  display: flex;
  justify-content: center;
}

.color-text-field {
  color: red !important;
}
