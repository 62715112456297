.custom-alert-container{
    width: 100%;
    height: 60px;
    background-color: #EAF5FC;
    //background-color:#333333;
    display: flex;
    //justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    padding-left: 18px;
    
}
.custom-alert-container::before{
    content: "";
    width:12px;
    height: 100%;
    background-color: #2C97DF; 
    position:relative;
    left:-19px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

}