.casino-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .button-section {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0px;
    span {
      color: #333333;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 900;
      font-family: "Roboto", sans-serif;
    }
    .btn-add {
      cursor: pointer;
    }
    i {
      font-size: 30px;
    }
  }
  .button-actions {
    button {
      outline: none;
      border-radius: 5px;
      min-width: 65px;
      padding: 10px;
      color: #ffffff;
      border: none;
      margin: 5px;
      &.btn-cancel {
        background-color: #ff5757 !important;
      }
      &.btn-save {
        background-color: #02d0e7 !important;
      }
    }
  }
}
