/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.my-sidebar {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  opacity: 1;
  height: 95vh !important;
  margin-top: 20px !important;
}

.my-sidebar::before {
  display: none;
}

.sidebar-icons {
  color: $gray-ligth !important;
  /*  width: 26px !important;
  height: 24px; */
  vertical-align: middle;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  font-size: 24px !important;
  float: left;
  margin-right: 12px;
  line-height: 30px;
}

.sidebar-text {
  color: $black !important;
  font-size: 14px !important;
  font-weight: 400;
}

.sidebar-text-sm {
  color: $black !important;
  font-size: 12px !important;
  font-weight: 400;
}

.sidebar-text-sm:hover {
  color: #0a9cac !important;
}

.sidebar-active-text {
  color: $primary !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.sidebar-active-link {
  color: $primary !important;
}

.sidebar-icon-sub-route {
  /*  position: relative;
  left: 80px; */
  font-size: 20px !important;
}

.sidebar-normal {
  margin-left: 10px;
}

.sidebar-text-sub-route {
  font-size: 12px !important;
  margin-left: 93px !important;
  font-weight: 400;
}

/* .line-indicator {
  
  width: 6px;
  height: 12px;
  background: #0097a8;
} */
.sidebar-text-sub-route::before {
  content: "";
  position: absolute;
  top: 0;
  left: 90px;
  width: 6px !important;
  height: 100%;
  background-color: $login-accent-color;
  border: 0px;
}

.sidebar-text-sub-route-blue {
  color: $black !important;
  font-size: 12px !important;
  margin-left: 93px !important;
  font-weight: 400;
}

.sidebar-text-sub-route-blue::before {
  content: "";
  position: absolute;
  top: 0;
  left: 90px;
  width: 6px !important;
  height: 100%;
  background-color: #00458d;
  border: 0px;
}

.sidebar-active-link::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: $primary;
  left: -76px;
  right: 0;
  top: 0;
  margin: auto;
  margin-bottom: -2px;
  transition: all 0.3s ease 0s;
}

.sidebar-danger-link {
  color: #ff4d38 !important;
}

.sidebar-item-container {
  margin-bottom: 39px;
}

.sidebar-bottom-container {
  position: absolute !important;
  bottom: 0px !important;
}

.sidebar-sm-item {
  font-size: 20px !important;
}

.sidebar-back-btn {
  margin-top: 5%;
  margin-left: 36px;
  font-size: 17px;
  color: #c3c3c3;
}

.logo-lg-sidebar {
  width: 200px;
  height: 52px;
  text-align: center;
  padding-left: 33px !important;
}

/* .logo-lg-sidebar:hover {
  width: 205px;
  padding-left: 25px !important;
  margin-top: -5px !important;
} */
.logo-lg-sidebar-mini {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 5px !important;
}

.logo-lg-sidebar-mini img {
  text-align: center;
  width: 33px !important;
  height: 44px !important;
}

/* .logo-lg-sidebar-mini:hover {
  padding-left: 2px !important;
  padding-bottom: 25px I !important;
}
.logo-lg-sidebar-mini:hover img {
  width: 40px !important;
  height: 50px !important;
} 

*/

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.separator-border {
  border-right: 2px solid #9e9e9e;
}

.center-icon {
  padding: 20px 10px 10px;
}

.my-navbar {
  width: 100%;
  display: flex;
  align-items: center;
}

.navbar-brand-name p {
  color: #00458d;
  margin-left: -25px;
  font-size: 20px;
}

.divider {
  height: 38px;
  width: 2px;
  margin: 9px 0;
  background-color: #87aecc;
}

.navbar-user {
  display: flex;
  flex-direction: column;
}

.date {
  font-size: 13px;
  color: $black;
}

.date-format {
  font-size: 12px;
  line-height: 14px;
}

.primary-text {
  font-weight: 400;
  font-size: 14px;
  color: $black;
}

.color-text {
  color: $black;
}

.capitalize-text {
  text-transform: capitalize;
}

.user-name {
  font-size: 13px;
  font-weight: 700;
  color: $black;
  text-align: right;
}

.navbar-bell {
  display: flex;
  align-items: center;
}

.bell-hover:hover {
  color: #00e5ff;
}

.navbar-bell i {
  margin-left: -24px;
  color: #87aecc;
}

.navbar-utilities {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-utilities p {
  color: #666666;
}

.navbar-utilities i {
  font-size: 8px;
  margin-left: 3px;
  color: #87aecc;
}

.navbar-utilities .question-icon {
  font-size: 14px;
  margin-left: 10px;
}

.navbar-logo {
  height: 25%;
  width: 25%;
}

.navbar-logo:hover {
  height: 27%;
  width: 27%;
}

.navbar-logo-mini {
  height: 25%;
  width: 25%;
}

.navbar-info {
  width: 100%;
}

.caption {
  font-size: 12px;
  color: $black;
  line-height: 16px;
  text-align: right;
  text-transform: capitalize;
}

.active-route-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: $black !important;
}

.subtitleModule {
  color: $black;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: $black !important;
}

.container-list {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0 10px;
}

.list-item {
  height: 126px;
  background-color: $white;
  margin-bottom: 2vw;
  border-radius: 8px;
  padding-top: 20px;
}

.item-text {
  color: $black !important;
  padding-top: 20%;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}

.circle-active {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid #1ea97c;
  margin: 0;
}

.list-item:hover {
  .circle {
    border: 3px solid #1ea97c;
  }

  cursor: pointer;
}

.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid $primary;
  margin: 0;
  /* position: relative;
  left: 35%; */
}

.shortcut-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.accordionSummary {
  flex-direction: row-reverse;
  border-left: transparent;
  border-right: transparent;
}

.reports-container {
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  opacity: 1;
  padding: 14px;
  min-height: 360px;

  .title {
    padding-left: 20px;
  }

  margin-bottom: 14px;
  margin-left: 10px;
}

.input-search {
  background-color: $white;
  border: 1px solid #cdcdcd;
  opacity: 1;
  width: 100%;
  height: 46px;
  color: #555555;
  font-size: 14px;
}

.input-icon {
  color: #555555;
  position: relative;
  right: 8px;
  top: -32px;
}

.input-search:focus {
  color: #555555;
  border: 1px solid #cdcdcd;
}

.input-search::placeholder {
  color: #555555;
}

.notification-wrapper {
  width: 100%;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 4 !important;
}

.notification-wrapper-sm {
  position: absolute;
  top: 39px;
  right: 10px;
  z-index: 4;
}

.txt-primary {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: $black;
  font-family: "Roboto", sans-serif;
}

.txt-primary-md {
  color: $black;
  font-size: 14px;
  opacity: 1;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.title-carrousel {
  color: $black;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.txt-primary-sm {
  color: $black;
  font-size: 11.5px;
  opacity: 1;
}

.txt-secondary {
  color: $secondary;
  font-size: 30px;
}

.card-container-read {
  // background-color: #0000000a;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 10px;
  width: 100%;
  padding: 8px 10px 8px 10px;
}

.card-container-notRead {
  background-color: #f5f5f5;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 10px;
  width: 100%;
  padding: 8px 10px 8px 10px;
}

.card-title-read {
  font-size: 13px;
  color: $black;
  line-height: 12px;
  font-weight: bold;
}

.card-title-notRead {
  font-size: 13px;
  color: #02d0e7;
  line-height: 12px;
  font-weight: bold;
}

.card-caption-read {
  font-size: 12px;
  line-height: 11px;
  color: #969696;
}

.card-caption-notRead {
  font-size: 12px;
  line-height: 11px;
  color: #0599aa;
}

.circle-green {
  width: 12px;
  height: 12px;
  border-radius: 50%;

  background: #04bf9d 0% 0% no-repeat padding-box;
  opacity: 1;
}

.circle-orange {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* UI Properties */
  background: #fa8072 0% 0% no-repeat padding-box;
  opacity: 1;
}

.circle-red {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* UI Properties */
  background: #e62e1b 0% 0% no-repeat padding-box;
  opacity: 1;
}

.circle-blue {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* UI Properties */
  background: #9fb8ed 0% 0% no-repeat padding-box;
  opacity: 1;
}

.circle-notification {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* UI Properties */
  background: #02d0e7 0% 0% no-repeat padding-box;
  opacity: 1;
}

.separator {
  width: 100%;
  border-bottom: 1.5px solid #dfdfdf;
}

.my-card {
  height: 107px;
  box-shadow: 0px 3px 6px #9fb8ed80;
  border-radius: 20px;
  padding: 0;
}

.my-card-body {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.my-card-row {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.my-card-row .my-card-img {
  text-align: center;
  z-index: 1050;
  display: flex;
  justify-content: center;
  padding-left: 10px;
}

.my-card p {
  text-align: left;
  letter-spacing: 0px;
  color: $white;
  margin: 0;
  padding: 0;
}

.my-card .card-days {
  color: #036a76 !important;
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
}

.container-img {
  width: 30px;
  height: 30px;
  background-color: $white;
  border-radius: 4px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reports-container p {
  color: #00458d;
  font-size: 20px;
  font-weight: normal;
  margin-left: 10px;
}

.reports-container .question-icon {
  display: inline-block;
  margin-top: 18px;
  color: #0097a8;
}

.login-logo {
  width: 303px;
  height: 303px;
  display: table;
  margin: 0 auto;
}

.btn-login {
  background-color: $login-light-primary-color !important;
  background-image: none;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

.btn-login:focus {
  outline: none;
}

.text-link {
  letter-spacing: 0px;
  color: $login-light-primary-color;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  text-transform: none !important;
}

.text-link:hover {
  cursor: pointer;
  color: #0599 !important;
}

.sidebar-wrapper {
  max-height: 85vh;

  .logo {
    padding-bottom: 0;
  }

  .nav {
    margin-top: 10px;

    li {
      a {
        padding: 2px 8px;
      }
    }
  }
}

.my-caret {
  color: $login-accent-color !important;
  right: -10px !important;
  //top: 10px !important;
  font-size: 20px !important;
}

.rootLoading {
  flex-grow: 1;
}

.paperLoading {
  height: 100%;
  width: 100%;
}

.colorLoading {
  color: $primary !important;
}

.box-account {
  border: 0.5px solid #cbcbcb;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-left: 2px;
  padding: 12px;
}

.box-account-active {
  border: 1px solid #00e5ff;
  box-shadow: 0px 3px 4px #64f6ff;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-left: 2px;
  padding: 12px;
}

.cursor {
  cursor: pointer;
}

@include media-breakpoint-up(lg) {
  .settings {
    display: block;
  }

  .settings-icon {
    display: none;
  }
}

@include media-breakpoint-between(md, lg) {
  .settings {
    display: none;
  }

  .settings-icon {
    display: block;
  }
}

@include media-breakpoint-between(sm, md) {
  .navbar-user .date {
    font-size: 11px;
    margin-top: 5px;
  }

  .navbar-user .user-name {
    font-size: 11px;
  }

  .navbar-utilities p {
    font-size: 10px;
  }

  .settings {
    display: none;
  }

  .settings-icon {
    display: block;
  }
}

.card-value {
  color: #036a76 !important;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}

.indicators-container {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}

/* #dialogLayout{
  max-width: 80vh;
} */
@include media-breakpoint-down(sm) {
  .my-navbar {
    width: 100%;
    display: flex !important;
  }

  .navbar-info {
    display: none;
  }

  .login-logo {
    height: min-content;
  }

  .settings {
    display: none;
  }

  .settings-icon {
    display: block;
  }

  #dialogLayout {
    max-height: 80vh;
  }
}

@include media-breakpoint-up(lg) {
  .list-item {
    width: 10.833333329%;
    flex: 0 0 10.833333329%;
    text-align: center;
  }

  .responsive-box-sm {
    height: 70%;
  }

  #dialogLayout {
    max-height: 80vh;
  }
}

@include media-breakpoint-between(md, lg) {
  .list-item {
    width: 10.833333329%;
    flex: 0 0 10.833333329%;
    text-align: center;
  }

  .responsive-box-sm {
    height: 70%;
  }

  #dialogLayout {
    max-height: 80vh;
  }
}

@include media-breakpoint-between(sm, md) {
  .list-item {
    width: 10.833333329%;
    flex: 0 0 10.833333329%;
    text-align: center;
  }

  .responsive-box-sm {
    height: 70%;
  }

  #dialogLayout {
    max-height: 80vh;
  }
}

@include media-breakpoint-down(sm) {
  .list-item {
    width: 24%;
    flex: 0 0 24%;
    max-width: 24%;
    text-align: center;
  }

  .responsive-box-sm {
    height: 100%;
  }

  #dialogLayout {
    max-height: 70vh;
  }
}

// CARD FILTRO

.filter {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  opacity: 1;
  padding: 18px 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 2px;
}

.box-games {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.box-games-img {
  width: 100%;
  height: auto !important;
  background-color: #007e8c;
  margin: 0;
  padding: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-switch {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.filter-container {
  background-color: #fff;
  //border-bottom: 1px solid rgba(2, 208, 231, 0.3);
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 2px;
  padding-bottom: 30px;
}

.opacity {
  opacity: 0.6;
}

.not-active {
  pointer-events: none;
  cursor: default;
}

// TITULOS PARA MODULOS
.titleModule {
  font-size: 25px !important;
  color: black !important;
  font-weight: 550;
  text-transform: uppercase;
  margin-bottom: 10px !important;
}

.subtitleFilter {
  font-size: 20px !important;
  color: black !important;
  text-transform: capitalize;
}

/** TEXTO EN BOTONES*/
.btn-label {
  font-size: 14px;
  z-index: 1;
  font-weight: 700;
  margin: 0px !important;
  cursor: pointer;
  color: #f1f1f1;
}

.btn-label-cancelar {
  font-size: 14px;
  font-weight: 600;
  margin: 0px !important;
  cursor: pointer;
  color: #fff;
}

/** BOTONES GENERICOS*/
.btn-light-dark {
  justify-content: center !important;
  border-radius: 10px;
  //background-color: #02d0e7;
  background-color: #007e8c;
  color: #f1f1f1;
  padding: 0.5rem 1.2rem;
  border: 0px;
  outline: 0px !important;
  font-family: "Open Sans", sans-serif !important;
  width: 120px;
}

.btn-light-grey {
  justify-content: center !important;
  border-radius: 10px;
  background-color: #e5e5e5;
  color: black;
  padding: 0.5rem 1.2rem;
  border: 0px;
  outline: 0px !important;
  font-family: "Open Sans", sans-serif !important;
  width: 120px;
}

.btn-white {
  border-radius: 10px;
  background-color: white;
  color: #02d0e7;
  justify-content: center !important;
  padding: 0.5rem 1.2rem;
  border: 0px;
  outline: 0px !important;
  font-family: "Open Sans", sans-serif !important;
  width: 120px;
}

.danger-btn {
  background-color: #ff5757 !important;
}

.ripple {
  padding: 20px 23px;
  position: relative;
  overflow: hidden;
}

.ripple::before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.268);
  width: 10%;
  height: 25%;
  border-radius: 50%;
  z-index: 1;
  top: 35%;
  left: 45%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.ripple:hover::before {
  -webkit-transform: scale(12);
  transform: scale(12);
  -webkit-transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
  transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, border-radius 0.5s 0.5s;
  transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
}

.ripple:hover::after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.secondary-btn {
  color: #495057 !important;
  background-color: #e5e5e5 !important;
}

.primary-btn {
  //background-color: #02d0e7 !important;
  background-color: #02d0e7 !important;
}

.white-container {
  background-color: #fff;
  border-radius: 25px;
  height: 95%;
  padding: 15px;
}

.white-container-auto-height {
  background-color: #fff;
  border-radius: 15px;
  height: auto;
  padding: 15px;
}

.label-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #747474;
  padding-left: 8px;
}

.dialog-wrapper {
  overflow-y: auto;
}

.carrousel-item {
  max-height: 400px !important;
  //overflow: auto;
}

.responsive-box-sm {
  height: "70%";
}

.hover-close:hover {
  color: #02d0e7 !important;
}

.hover-close:hover i {
  color: #02d0e7 !important;
}

.legend-title {
  margin-top: -15px !important;
  color: #00458d;
}

.legend-container {
  background-color: #007e8c !important;
}

.legend-container span {
  margin-top: -0.5rem !important;
}

.legend-container-lg span {
  margin-top: -1rem !important;
  /*   font-family: 'Roboto', sans-serif !important;
  font-weight: 500; */
}

.box-personal-icon {
  background-color: #007e8c;
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
  height: 39px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-overflow {
  width: 100%;
  height: auto;
  word-wrap: break-word;
  padding-right: 15px;
}

.word-break {
  white-space: normal;
  word-break: break-word;
}

//BREADCUMB SEPARATOR
ul li,
ol li {
  color: #333333;
}

@media only screen and (max-width: 580px) {
  .btn-light-dark {
    width: 100% !important;
  }

  .btn-light-grey {
    width: 100% !important;
  }
}

.ql-snow * {
  color: black;
}

.ql-editor {
  height: 250px;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    height: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }
}

.ql-toolbar.ql-snow {
  border: 0.5px solid #ccc;
  border-bottom: 0px;
  background: #f4f4f4;
}

.scroll-notifications {
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
.MuiTabs-flexContainer{
  overflow-x: auto;
}