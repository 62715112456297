.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  opacity: 1;
  padding: 18px 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 2px;
  width: 100%;
}
.footer-btn {
  width: 150px !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
}
.btn-dialog {
  width: auto !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
}
.primary-btn {
  background-color: #02d0e76f !important;
}
.lef-img-dialog {
  position: absolute;
  right: 0;
}
.right-img-dialog {
  position: absolute;
  bottom: 0;
  left: 0;
}
.no-margin {
  margin: 0 !important;
}
