/* Set content for font-families */
.ql-picker .ql-font .ql-picker-item {
    font-size: 0;
}

.ql-picker .ql-font .ql-picker-item:before {
  content: attr(data-value) !important;
  font-size: 14px;
}

.ql-picker.ql-font .ql-active:before{
      content: attr(data-value) !important;
      font-size: 14px;  
}

.ql-picker.ql-font .ql-picker-label[data-value="arial"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="arial"]::before
{
    font-family: "Arial", sans-serif;
    content: "Arial" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before
{
    font-family: "Comic Sans MS", cursive, sans-serif;
    content: "Comic Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", cursive;;
    content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
    font-family: "Raleway", cursive;;
    content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
{
    font-family: "Montserrat", cursive;;
    content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
{
    font-family: "Lato", cursive;;
    content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
{
    font-family: "Rubik", cursive;;
    content: "Rubik" !important;
}

/* Set content font-families */
.ql-font-arial {
  font-family: "arial";
}
.ql-font-comic-sans {
  font-family: "Comic Sans MS";
}
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
}

.ql-undo:hover * {
    color: #06c;
}
