.table-container {
  padding: 20px;
}

.table-controls {
  display: flex;
  margin-bottom: 10px;

  .pagination-controls {
    display: flex;

    .page-size {
      margin-left: 10px;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    color: #333333 !important;
  }

  th {
    background-color: #f4f4f4;
    color: #333333 !important;
  }
  tbody.tbody-table {
    tr {
      td {
        color: #333333 !important;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  button {
    margin: 0 5px;
    padding: 10px;
    border: none;
    background: none;
    cursor: pointer;

    &.active {
      background-color: #007bff;
      color: white;
      border-radius: 50%;
    }

    &.page-number {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7f1ff;
      border-radius: 50%;
      font-size: 1rem;
    }

    &.navigation {
      font-size: 1.5rem;
      color: #007bff;
    }
  }
}
