.my-card-container {
  height: 200px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
  border-radius: 10px !important;
  //display: flex;
  //justify-content: "center";
  //align-items: "center";
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;

  text-align: center;
  padding: 0px !important;
  cursor: pointer;
}
.my-card-container:hover{
  opacity: 0.63;
}
.my-card-container-sm {
  height: 100px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
  border-radius: 10px !important;
  //display: flex;
  //justify-content: "center";
  //align-items: "center";
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;

  text-align: center;
  padding: 0px !important;
}
.my-card-title {
  font-weight: 700 !important;
  //font-size: 20px ;
  line-height: 10px;

  color: #ffffff ;
  position: relative ;
  top: 0px;
}
.my-card-title-sm {
  font-weight: 700 !important;
  //font-size: 16px ;
  line-height: 10px;

  color: #ffffff ;
  position: relative ;
  top: 0px;
}
.my-card-content {
  font-weight: 700 !important;
  font-size: 30px;
  line-height: 80px;
  /* identical to box height */

  color: #ffffff;
}
.my-card-content-sm {
  font-weight: 700 !important;
  font-size: 26px;
  line-height: 40px;
  /* identical to box height */

  color: #ffffff;
}
