.input-file-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 34px;
  background-color: #007e8c;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.card-content {
  width: 100%;
  height: 200px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.button-container {
  margin-top: -20px;
  margin-right: -10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  .circle-button {
    width: 40px;
    height: 40px;
    background: #d9d9d9;
    border: 3px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
