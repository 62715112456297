.btn-add {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #02d0e7;
  border: 2px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff
}
