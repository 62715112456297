.barra {
  border-bottom: solid 2px #dfdfdf;
}

.inner-addon {
  position: relative;
}

.inner-addon .aico {
  position: absolute;
}

.left-addon .aico {
  left: 95%;
  padding-top: 7px;
}

.left-addon input {
  padding-right: 10px;
}

.aico {
  color: rgb(4, 130, 251);
  z-index: 1;
  font-size: 20px;
}

.left-addon-user .aico {
  left: 90%;
  padding-top: 8px;
}

.left-addon-associate .aico {
  right: 88%;
  padding-top: 8px;
}

.clr {
  color: #5c6063 !important;
}

.left-addon-associate input {
  padding-left: 35px;
  border: 1px solid #007e8c;
  border-radius: 5px;
}

.input-app-sm {
  border-radius: 4px;
  background-color: #f2f2f2;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: 0px;
  outline: medium none;
  font-family: "Open Sans", sans-serif !important;
}

.iconTableSubMenu {
  cursor: pointer;
}

.cellRowActive {
  display: flex;
  justify-content: center;
  background-color: #c7f5d9;
  border-radius: 2px;
}

.cellRowPaid {
  display: flex;
  justify-content: center;
  color: #007e8c;
  border-radius: 2px;
}
.cellRowButtonPaid {
  display: flex;
  justify-content: center;
  color: white;
  background-color: #007e8c;
  border-radius: 2px;
  cursor: pointer;
}

.cellRowInactive {
  display: flex;
  justify-content: center;
  background-color: #f98172;
  border-radius: 2px;
}

.cellRowRetired {
  display: flex;
  justify-content: center;
  background-color: #ebebeb;
  border-radius: 2px;
}

.iconEdit {
  color: #007e8c;
  cursor: pointer;
}

.iconSeeMore {
  color: #02d0e7;
  cursor: pointer;
}

.iconSee {
  color: #333333;
  cursor: pointer;
}

.iconDelete {
  color: #ff5757;
  cursor: pointer;
}

.iconAdd {
  color: #04bf9d;
  cursor: pointer;
}

.iconApprove {
  color: #007e8c;
  cursor: pointer;
}

.iconAttach {
  color: #333333;
  cursor: pointer;
}

.buttonActive {
  background-color: #007e8c;
  color: white;
  border: none;
  outline: none !important;
  box-shadow: none;
  border-radius: 5px;
  padding: 4px 8px;
}

.buttonActive:disabled {
  opacity: 0.5;
}

.divInputBuscar {
  width: 30%;
}
.divButtonCrear {
  width: 70%;
}

.btn-light-crear {
  justify-content: center !important;
  border-radius: 10px;
  background-color: #02d0e7;
  color: #f1f1f1;
  padding: 0.5rem 1.2rem;
  border: 0px;
  outline: 0px !important;
  font-family: "Open Sans", sans-serif !important;
  width: 200px;
}

.tableCard {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 960px) {
  .left-addon-user .aico {
    left: 88%;
  }
  .divInputBuscar {
    width: 50%;
  }
  .divButtonCrear {
    width: 50%;
  }
}
@media only screen and (max-width: 770px) {
  .left-addon-user .aico {
    left: 86%;
  }
}

@media only screen and (max-width: 570px) {
  .btn-light-crear {
    width: 100%;
    padding: 0.4rem 1.2rem;
  }
}
