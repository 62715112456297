.divText {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pText {
  color: black;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}
