@import "../ManagementMenu/FormCreateMenu.scss";
@import "../ManagementUsers/formCreateUser.scss";

.boxAddRol {
  background-color: #fff;
  border: 1px dashed #b7bcc1;
  box-sizing: border-box;
  border-radius: 6px;
  height: 86px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container-lg {
  height: 75vh;
  overflow-y: auto;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-btn {
  margin-left: 24px !important;
  width: 132px !important;
  justify-content: center !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  padding: 10px 8px 10px !important;
  text-transform: none !important;
  i {
    margin-right: 10px;
  }
}

.check-list-item {
  /* border-top: 1.5px solid #DADADA !important; */
  border-bottom: 1.5px solid #dadada !important;
}

@media only screen and (max-width: 577px) {
  .footer-btn {
    width: 97.81px !important;
    font-size: 11px !important;
  }
}
