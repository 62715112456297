.tabs-managementPlayers {
  .dynamic-toogle-tabs {
    align-items: center;
    margin-top: 20px;

    .toogle-button-container {
      padding: 0 !important;

      button {
        padding: 0px;
      }
    }

    .dynamic-tabs-container {
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 10px;
      margin: 0 !important;

      .tab {

        .option-btn,
        .option-btn-active {
          height: 55px;

          .txt-primary-sm {
            margin: 0;
            font-size: 16px;

            @media only screen and (max-width: 1525px) {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}