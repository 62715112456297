.icon {
  text-align: center;
  background-color: #f5f5f5;
  cursor: pointer;
}

.form-control {
  color: #000 !important;
}
.custom-select {
  color: #000 !important;
}

.btn {
  padding-top: 12px !important;
  padding-left: 15px !important;
}

.spanMoreFilter {
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  text-transform: initial;
}

@media only screen and (min-width: 1500px) {
  .divButton {
    padding-left: 0px !important;
    margin: 0 !important;
  }
}

@media only screen and (max-width: 1280px) {
  .divButton {
    display: flex;
    padding-left: 50px !important;
    margin: 0 !important;
  }
}

@media only screen and (min-width: 1280px) {
  .divInput {
    width: 27%;
    padding-right: 10px;
  }
  .divButton {
    width: 10%;
    padding-left: 10px !important;
  }
  .queryDiv {
    display: flex;
    width: 10%;
    padding-left: 10px !important;
  }
  .queryButtonCel {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .divInput {
    width: 26%;
    padding-right: 10px;
  }
  .divButton {
    display: flex;
    width: 10%;
    padding-left: 10px !important;
  }
  .queryDiv {
    display: flex;
    width: 10%;
    padding-left: 10px !important;
  }
  .test {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .divInput {
    width: 50%;
    padding-right: 10px;
  }
  .divButton {
    display: flex;
    width: 50%;
    padding-left: 10px !important;
  }
  .queryDiv {
    display: none;
  }
}
@media only screen and (max-width: 580px) {
  .queryDiv {
    display: none;
  }
  .divInput {
    width: 100%;
    padding-right: 10px;
  }
  .divButton {
    display: flex;
    width: 100%;
    padding-left: 10px !important;
  }
}